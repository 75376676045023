import clsx from 'clsx'
import { FC } from 'react'
import styles from './checkbox.module.scss'

interface IProps {
	isChecked?: boolean
	onChange?: VoidFunction
}

export const Checkbox: FC<IProps> = ({ isChecked, onChange }) => {
	return (
		<>
			<label className={clsx(styles.container, 'checkbox__container')}>
				<input checked={isChecked} onChange={onChange} type='checkbox' />
				<i className={clsx(styles.checkmark, 'checkbox__checkmark')}></i>
			</label>
		</>
	)
}
