import Cookies from 'js-cookie'
import { TTokens } from '../types/common.type'

export const saveTokensStorage = (data: TTokens) => {
	localStorage.setItem('access_token', data.access_token)
	localStorage.setItem('refresh_token', data.refresh_token)
	window.dispatchEvent(new Event('storage'))
}

export const removeTokensStorage = () => {
	localStorage.removeItem('access_token')
	localStorage.removeItem('refresh_token')
	Cookies.remove('token')
	window.dispatchEvent(new Event('storage'))
}
