import { toFormData } from 'axios'
import { axiosInstance } from 'shared/api'
import { getUsersUrl } from 'shared/api/config/api.config'
import { TUsers } from './types/users.type'

export const UsersService = {
	async create(data: TUsers.Create) {
		const response = await axiosInstance.post<TUsers.CreateSuccess>(
			getUsersUrl('/'),
			toFormData(data)
		)

		return response
	},

	async delete(user_id: number) {
		const response = await axiosInstance.delete<TUsers.DeleteSuccess>(
			getUsersUrl('/' + user_id + '/delete')
		)

		return response
	},

	async changePassword(data: TUsers.ChangePassword) {
		const response = await axiosInstance.patch<TUsers.ChangePasswordSuccess>(
			getUsersUrl('/change_password'),
			toFormData(data)
		)

		return response
	},

	async getItem(id: TUsers.GetUser) {
		const response = await axiosInstance.get<TUsers.GetUserSuccess>(
			getUsersUrl('/' + id)
		)

		return response
	},

	async getMe() {
		const response = await axiosInstance.get<TUsers.GetUserSuccess>(
			getUsersUrl('/current_user/')
		)

		return response
	},

	async getList(data: TUsers.GetList) {
		const response = await axiosInstance.get<TUsers.GetListSuccess>(
			getUsersUrl('/list'),
			{
				params: {
					limit: data.limit,
					offset: data.offset,
					ids: data.ids,
					role: data.role,
					order_by: data.order_by,
					is_desc_sort: data.is_desc_sort
				}
			}
		)

		return response
	}
}
