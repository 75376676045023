import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'

interface IProps extends PropsWithChildren {
	onSubmit: any
	methods: UseFormReturn<FieldValues, any, undefined>
	className: string
	id?: string
}

export const FormWrapper: React.FC<IProps> = ({
	className,
	methods,
	children,
	onSubmit,
	id,
}) => {
	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(onSubmit)}
				className={clsx(className, 'form')}
				id={id}
			>
				{children}
			</form>
		</FormProvider>
	)
}
