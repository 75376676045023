import clsx from 'clsx'
import { FC } from 'react'
import { ReactComponent as PopupCloseIcon } from 'shared/assets/icons/popup-close-icon.svg'

interface IProps {
	className?: string
	onClick?: () => void
}

export const CloseIcon: FC<IProps> = ({ className, onClick }) => {
	return (
		<div onClick={onClick} className={clsx(className, 'closeIcon')}>
			<PopupCloseIcon />
		</div>
	)
}
