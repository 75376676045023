import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TUsers } from 'shared/services/types/users.type'
import { UsersService } from 'shared/services/users.service'

export const useActionsUsers = () => {
	const queryClient = useQueryClient()

	const create = useMutation({
		mutationFn: (data: TUsers.Create) => UsersService.create(data),
		mutationKey: ['create user'],
		onSuccess: () => {
			console.log('xxxx');
			
			queryClient.invalidateQueries({ queryKey: ['get users list'] })
		},
	})

	const deleteItem = useMutation({
		mutationFn: (user_id: number) => UsersService.delete(user_id),
		mutationKey: ['delete user'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['get users list'] })
		},
	})

	const changePassword = useMutation({
		mutationFn: (data: TUsers.ChangePassword) =>
			UsersService.changePassword(data),
		mutationKey: ['change password'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['get current user'] })
		},
	})

	return { create, deleteItem, changePassword }
}
