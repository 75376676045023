import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	className?: string
}

export const PopupHeader: React.FC<IProps> = ({ className, children }) => {
	return <div className={clsx(className, 'popup__header')}>{children}</div>
}
