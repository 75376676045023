import { Applications } from 'pages/applications'
import { ErrorPage } from 'pages/error404'
import { Landings } from 'pages/landings'
import { Login } from 'pages/login'
import { Profile } from 'pages/profile'
import { Statistic } from 'pages/statistic'
import { Users } from 'pages/users'
import { createBrowserRouter } from 'react-router-dom'
import { ProtectedRoute } from './protected-route'

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<Statistic />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	},
	{
		path: '/login',
		element: (
			<ProtectedRoute>
				<Login />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	},
	{
		path: '/requests',
		element: (
			<ProtectedRoute onlyOperator>
				<Applications />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	},
	{
		path: '/profile',
		element: (
			<ProtectedRoute onlyOperator>
				<Profile />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	},
	{
		path: '/landings',
		element: (
			<ProtectedRoute>
				<Landings />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	},
	{
		path: '/users',
		element: (
			<ProtectedRoute>
				<Users />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />
	}
])
