import clsx from 'clsx'
import { CloseIcon } from 'entities/close-icon'
import { FC, PropsWithChildren } from 'react'
import Popup from 'reactjs-popup'

interface IProps extends PropsWithChildren {
	trigger?: JSX.Element
	className?: string
	children?: any
	open?: any
	close?: any
	onClose?: any
}

export const Modal: FC<IProps> = ({ className, open, children, onClose }) => {
	return (
		<Popup open={open} className={clsx(className)} onClose={onClose} modal>
			<CloseIcon className='ApplicationDetails-close' onClick={onClose} />
			{children}
		</Popup>
	)
}
