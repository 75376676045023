import { axiosInstance } from 'shared/api'
import { getApplicationsUrl } from 'shared/api/config/api.config'
import { TStatistics } from './types/statistic.type'
import { addDays } from 'date-fns';

export const StatisticService = {
	
	async getItem(data?: TStatistics.GetItem) {
		const response = await axiosInstance.get<TStatistics.SuccessResponse>(
			getApplicationsUrl('/statistics/'),
			{
				params: {
					date_from: data?.date_from,
					date_to: data?.date_to ? addDays(new Date(data?.date_to), 1)  : null
				}
			}
		)

		return response
	},

	async getMonth(data?: TStatistics.GetItem) {
		const response = await axiosInstance.get<TStatistics.MonthSuccessResponse>(
			getApplicationsUrl('/monthly-statistics/'),
			{
				params: {
					...data,
					date_to: data?.date_to ? addDays(new Date(data?.date_to), 1)  : null
				}
			}
		)

		return response
	}
}
