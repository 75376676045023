import clsx from 'clsx'
import { FC } from 'react'
import styles from './status.module.scss'

interface IProps {
	isActive?: boolean
	isArchive?: boolean
	isAgent?: boolean
}

export const Status: FC<IProps> = ({ isActive, isArchive, isAgent }) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				isActive && styles.active,
				isArchive && styles.archive,
				isAgent && styles.agent,
				'status__wrapper'
			)}
		>
			<span>
				{(isActive && 'Актив') ||
					(isArchive && 'Архив') ||
					(isAgent && 'Агент')}
			</span>
		</div>
	)
}
