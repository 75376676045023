import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'
import { LandingsService } from 'shared/services/landings.service'
import { TLandings } from 'shared/services/types/landings.type'
import { addMessageToast } from 'shared/utils/addMessageToast'

export const useGetLandingsList = (data: TLandings.GetList) => {
	const queryClient = useQueryClient()

	const getLandingsList = useQuery({
		queryFn: () => LandingsService.getList(data),
		queryKey: ['get landings list', data],
		retry: 0,
		enabled: !!data.limit,
		select: ({ data }) => data,
	})

	useEffect(() => {
		if (getLandingsList.isError) {
			addMessageToast('Error')
		}
	}, [getLandingsList.isError])
	return getLandingsList
}
