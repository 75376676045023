import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	className?: string
}

export const PageTitle: FC<IProps> = ({ children, className }) => {
	return (
		<h2 className={clsx(className, 'pageHeader__title', 'title')}>
			{children}
		</h2>
	)
}
