import React, { FC } from 'react'

interface IProps {
	children: React.ReactNode
}

export const Bar: FC<IProps> = ({ children }) => {
	return (
		<div className='bar'>
			<div className='bar__items'>{children}</div>
		</div>
	)
}
