import clsx from 'clsx'
import { PopupFooter } from 'components/popup/popupFooter'
import { PopupHeader } from 'components/popup/popupHeader'
import { PopupTitle } from 'components/popup/popupTitle'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { PopupLightIcon, PopupSuccessIcon } from 'shared/assets/icons'
import { Button } from 'shared/ui'
import { Modal } from 'shared/ui/modal'
import styles from './styles.module.scss'

interface IProps {
	isPopupOpen: boolean
	popupStep: number
	setPopupStep: React.Dispatch<React.SetStateAction<number>>
	onClose: VoidFunction
	toggleStatus: any
}

export const ModalApplications: FC<IProps> = ({
	isPopupOpen,
	popupStep,
	setPopupStep,
	onClose,
	toggleStatus,
}) => {
	const methods = useForm({ mode: 'onBlur' })

	const closePopup = () => {
		methods.reset({})
		onClose()
	}

	return (
		<Modal onClose={onClose} open={isPopupOpen}>
			<div
				className={clsx(
					styles.aplicationsModalWrapper,
					popupStep === 2 ? styles.aplicationsModalWrapper2 : ''
				)}
			>
				{(popupStep === 0 && (
					<>
						<PopupHeader>
							<PopupLightIcon />
						</PopupHeader>
						<div className={clsx(styles.popupstep, 'popup__content')}>
							<PopupTitle>Хотите изменить статус на Архивный агент?</PopupTitle>
						</div>
						<PopupFooter>
							<Button type='button' isLight text='Нет' onClick={closePopup} />
							<Button type='button' text='Да' onClick={toggleStatus} />
						</PopupFooter>
					</>
				)) ||
					(popupStep === 1 && (
						<>
							<PopupHeader>
								<PopupLightIcon />
							</PopupHeader>
							<div className={clsx(styles.popupstep, 'popup__content')}>
								<PopupTitle>
									Хотите изменить статус на Активный агент?
								</PopupTitle>
							</div>
							<PopupFooter>
								<Button type='button' isLight text='Нет' onClick={closePopup} />
								<Button type='button' text='Да' onClick={toggleStatus} />
							</PopupFooter>
						</>
					)) ||
					(popupStep === 2 && (
						<>
							<PopupHeader>
								<PopupSuccessIcon />
							</PopupHeader>
							<div className='popup__content'>
								<PopupTitle>Статус успешно обновлен</PopupTitle>
							</div>

							<PopupFooter>
								<Button
									type='button'
									isSuccess
									onClick={closePopup}
									text='Ок'
								/>
							</PopupFooter>
						</>
					))}
			</div>
		</Modal>
	)
}
