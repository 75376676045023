import { toFormData } from 'axios'
import { axiosInstance } from 'shared/api'
import { getLandingsUrl } from 'shared/api/config/api.config'
import { TLandings } from './types/landings.type'

export const LandingsService = {
	async create(data: TLandings.Create) {
		const response = await axiosInstance.post<TLandings.CreateSuccess>(
			getLandingsUrl('/'),
			toFormData(data)
		)

		return response
	},

	async delete(data: number) {
		const response = await axiosInstance.delete<TLandings.CreateSuccess>(
			getLandingsUrl('/' + data + '/delete')
		)

		return response
	},

	async update(id: number, data: TLandings.UpdateItem) {
		const response = await axiosInstance.patch<TLandings.CreateSuccess>(
			getLandingsUrl('/' + id + '/update'),
			toFormData(data)
			// {
			// 	params: {
			// 		domain_name: data.domain_name,
			// 		statistic_token: data.statistic_token,
			// 		authorisation_token: data.authorisation_token,
			// 	},
			// }
		)

		return response
	},

	async getItem(data: TLandings.GetItem) {
		const response = await axiosInstance.get<TLandings.GetItemSuccess>(
			getLandingsUrl('/' + data)
		)

		return response
	},

	async getList(data: TLandings.GetList) {
		const response = await axiosInstance.get<TLandings.GetListSuccess>(
			getLandingsUrl('/list/'),
			{
				params: {
					limit: data.limit,
					offset: data.offset,
					ids: data.ids,
					domain_name: data.domain_name,
					order_by: data.order_by,
					is_desc_sort: data.is_desc_sort
				}
			}
		)

		return response
	}
}
