import { useQuery } from '@tanstack/react-query'
import { UsersService } from 'shared/services/users.service'

export const useGetCurrentUser = () => {
	const token = localStorage.getItem('access_token')
	const getUser = useQuery({
		queryFn: () => UsersService.getMe(),
		queryKey: ['get current user', token],
		enabled: !!token,
		select: ({ data }) => data,
		staleTime: Infinity
	})

	return getUser
}
