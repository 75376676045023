import clsx from 'clsx'
import { PopupFooter } from 'components/popup/popupFooter'
import { PopupHeader } from 'components/popup/popupHeader'
import { PopupTitle } from 'components/popup/popupTitle'
import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {
	PopupLightIcon,
	PopupSuccessIcon,
	TrashIcon
} from 'shared/assets/icons'
import { Button, FormWrapper, Input } from 'shared/ui'
import { Modal } from 'shared/ui/modal'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { useActionsLandings } from '../api'
import styles from './styles.module.scss'

interface IProps {
	isPopupOpen: boolean
	onClose: VoidFunction
	popupStep: number
	data: any
	setPopupStep: React.Dispatch<React.SetStateAction<number>>
	deleteItem: (data: any) => void
	openPopup: (isEditMode?: boolean, data?: any) => void
}

export const ModalLendings: FC<IProps> = ({
	isPopupOpen,
	popupStep,
	data,
	setPopupStep,
	onClose,
	deleteItem,
	openPopup
}) => {
	const methods = useForm({ mode: 'onBlur' })
	const { createLanding, updateLanding } = useActionsLandings()
	const isEdit = data

	useEffect(() => {
		methods.reset({ domain_name: data?.domain_name })
	}, [data])

	const onSubmit = async (data: any) => {
		try {
			if (isEdit) {
				await updateLanding.mutateAsync({
					id: isEdit.id,
					domain_name: data.domain_name,
					authorisation_token: localStorage.getItem('access_token')!,
					statistic_token: localStorage.getItem('refresh_token')!,
				})
				setPopupStep(3)
				methods.reset({})
			} else {
				await createLanding.mutateAsync(data)
				setPopupStep(3)
				methods.reset({})
			}
		} catch (error) {
			addMessageToast('Ошибка, попробуйте позже!')
		}
	}

	const closePopup = () => {
		methods.reset({})
		onClose()
	}

	return (
		<Modal onClose={onClose} open={isPopupOpen}>
			{(popupStep === 0 && (
				<>
					<PopupHeader>
						<TrashIcon className={clsx(styles.trashicon)} />
					</PopupHeader>
					<div className='popup__content'>
						<PopupTitle>Хотите удалить ?</PopupTitle>
					</div>
					<PopupFooter>
						<Button type='button' isLight text='Нет' onClick={closePopup} />
						<Button type='button' text='Да' onClick={deleteItem} />
					</PopupFooter>
				</>
			)) ||
				(popupStep === 1 && (
					<>
						<PopupHeader>
							<PopupLightIcon />
						</PopupHeader>
						<div className='popup__content'>
							<PopupTitle>Хотите изменить ?</PopupTitle>
						</div>
						<PopupFooter>
							<Button type='button' isLight text='Нет' onClick={closePopup} />
							<Button
								type='button'
								text='Да'
								onClick={() => {
									openPopup(true)
								}}
							/>
						</PopupFooter>
					</>
				)) ||
				(popupStep === 2 && (
					<>
						<div className='popup__content'>
							<PopupTitle>{(isEdit && 'Изменить') || 'Добавить'}</PopupTitle>
							<FormWrapper
								className={clsx('form')}
								methods={methods}
								onSubmit={onSubmit}
								id='formInput'
							>
								<Input
									placeholder='exampleproduct.com'
									control={methods.control}
									name='domain_name'
									type='url'
									errors={methods.formState.errors}
									rules={{ required: 'обязательное поле' }}
								/>
							</FormWrapper>
						</div>

						<PopupFooter>
							<Button
								type='button'
								isLight
								text='Отмена'
								onClick={closePopup}
							/>
							<Button
								type='submit'
								form='formInput'
								text={(isEdit && 'Изменить') || 'Добавить'}
							/>
						</PopupFooter>
					</>
				)) ||
				(popupStep === 3 && (
					<>
						<PopupHeader>
							<PopupSuccessIcon />
						</PopupHeader>
						<div className='popup__content'>
							<PopupTitle>Домен успешно добавлен</PopupTitle>
						</div>

						<PopupFooter>
							<Button type='button' isSuccess onClick={closePopup} text='Ок' />
						</PopupFooter>
					</>
				))}
		</Modal>
	)
}
