import { useQuery } from '@tanstack/react-query'
import { eachMonthOfInterval, format, parse } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { StatisticService } from 'shared/services/statistic.service'
import { TStatistics } from 'shared/services/types/statistic.type'

function formatDateUsingDateFns(inputStr: number) {
	// Преобразуем в объект Date

	const date = parse(String(inputStr), 'yyyyMM', new Date())

	// Форматируем дату в желаемый формат
	return format(date, 'yyyy-MM')
}

export const useGetStatistic = (dates: TStatistics.GetItem) => {
	const [monthStatistics, setMonthStatistics] = useState<any>({})
	const dateFrom = useMemo(() => new Date(dates.date_from!), [dates])
	const dateTo = useMemo(() => new Date(dates.date_to!), [dates])

	const monthsArray = useMemo(
		() =>
			eachMonthOfInterval({
				start: dateFrom,
				end: dateTo
			}).reduce(
				(acc:any, monthDate:any) => ({ ...acc, [format(monthDate, 'yyyy-MM')]: 0 }),
				{}
			),
		[dateFrom, dateTo]
	)

	const result = useQuery({
		queryFn: () => StatisticService.getMonth(dates),
		queryKey: ['get stat', dates],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data.data.statistics
	})


	useEffect(() => {
		if (result.data) {
			const res = result.data.reduce((acc, item) => {
				const monthDate = formatDateUsingDateFns(item.month_number)
				return { ...acc, [monthDate]: item.count_of_applications }
			}, {})

			setMonthStatistics(res)
		}
	}, [result.data])

	const data = useMemo(
		() => ({ ...monthsArray, ...monthStatistics }),
		[monthStatistics, monthsArray]
	)

	return { ...result, data: data }
}
