import { useGetCurrentUser } from 'features/users/api'
import { ErrorPage } from 'pages/error404'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IProps {
	children: React.ReactElement<any, any> | null
	onlyOperator?: boolean
}

export const ProtectedRoute: FC<IProps> = ({ children, onlyOperator }) => {
	const location = useLocation()
	const { data } = useGetCurrentUser()
	const navigate = useNavigate()
	const [refresh, setRefresh] = useState(localStorage.getItem('refresh_token'))
	useEffect(() => {
		if (!refresh) {
			navigate('/login')
		} else if (refresh && location.pathname === '/login') {
			navigate('/profile')
		}
	}, [refresh, location.pathname])
	const watchStorage = () => {
		setRefresh(localStorage.getItem('refresh_token'))
	}
	useEffect(() => {
		window.addEventListener('storage', watchStorage)
		return () => {
			window.removeEventListener('storage', watchStorage)
		}
	}, [])
	if (
		(onlyOperator && data?.role === 'operator') ||
		data?.role === 'administrator' ||
		!data
	)
		return children
	// if (onlyOperator && data?.role === 'operator') return children
	// if (!onlyOperator) return children

	return <ErrorPage />
}
