import { FC, PropsWithChildren } from 'react'
import { SideNav } from 'widgets/side-nav'

export const Layout: FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className='App--wrapper'>
			<SideNav />
			<div className='pageWrapper'>{children}</div>
		</div>
	)
}
