import clsx from 'clsx'
import { Layout } from 'shared/ui'

export const ErrorPage = () => {
	return (
		<Layout>
			<div className={clsx('pageContent')}>
				<div id='error-page'>
					<h1>Oops!</h1>
					<p>Sorry, an unexpected error has occurred.</p>
				</div>
			</div>
		</Layout>
	)
}
