import clsx from 'clsx'
import { PopupFooter } from 'components/popup/popupFooter'
import { PopupHeader } from 'components/popup/popupHeader'
import { PopupTitle } from 'components/popup/popupTitle'
import { FC } from 'react'
import { useForm } from 'react-hook-form'
import { PopupSuccessIcon, TrashIcon } from 'shared/assets/icons'
import { RoleOptions } from 'shared/constants'
import { Button, FormWrapper, Input } from 'shared/ui'
import { FormSelect } from 'shared/ui/form-select'
import { Modal } from 'shared/ui/modal'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { useActionsUsers } from '../api'
import styles from './styles.module.scss'

interface IProps {
	isPopupOpen: boolean
	onClose: VoidFunction
	popupStep: number
	setPopupStep: React.Dispatch<React.SetStateAction<number>>
	deleteItem: (data: any) => void
}

export const ModalUsers: FC<IProps> = ({
	isPopupOpen,
	popupStep,
	setPopupStep,
	onClose,
	deleteItem,
}) => {
	const methods = useForm({ mode: 'onBlur' })
	const { create } = useActionsUsers()

	const onSubmit = async (data: any) => {
		try {
			await create.mutateAsync({ ...data, role: data.role.value })
			setPopupStep(2)
			methods.reset({})
		} catch (error) {
			addMessageToast('Ошибка, попробуйте позже!')
		}
	}

	const closePopup = () => {
		onClose()
		methods.reset({})
	}

	return (
		<Modal onClose={closePopup} open={isPopupOpen}>
			<div className={clsx(popupStep === 1 && styles.bigPadding)}>
				{(popupStep === 0 && (
					<>
						<PopupHeader>
							<TrashIcon className={clsx(styles.trashicon)} />
						</PopupHeader>
						<div className='popup__content'>
							<PopupTitle>Хотите удалить ?</PopupTitle>
						</div>
						<PopupFooter>
							<Button type='button' isLight text='Нет' onClick={closePopup} />
							<Button type='button' text='Да' onClick={deleteItem} />
						</PopupFooter>
					</>
				)) ||
					(popupStep === 1 && (
						<>
							<div className='popup__content'>
								<PopupTitle>Добавить пользователя</PopupTitle>
								<FormWrapper
									className={clsx('form')}
									methods={methods}
									onSubmit={onSubmit}
									id='FormUsers'
								>
									<FormSelect
										placeholder='Тип'
										options={RoleOptions}
										control={methods.control}
										name='role'
										errors={methods.formState.errors}
										rules={{ required: 'обязательное поле' }}
									/>
									<Input
										placeholder='Логин'
										control={methods.control}
										name='login'
										type='text'
										errors={methods.formState.errors}
										rules={{ required: 'обязательное поле' }}
									/>
									<Input
										placeholder='Пароль'
										control={methods.control}
										name='password'
										type='password'
										errors={methods.formState.errors}
										rules={{ required: 'обязательное поле' }}
									/>
								</FormWrapper>
							</div>

							<PopupFooter>
								<Button
									type='button'
									isLight
									text='Отмена'
									onClick={closePopup}
								/>
								<Button type='submit' text='Добавить' form='FormUsers' />
							</PopupFooter>
						</>
					)) ||
					(popupStep === 2 && (
						<>
							<PopupHeader>
								<PopupSuccessIcon />
							</PopupHeader>
							<div className='popup__content'>
								<PopupTitle>Пользователь добавлен</PopupTitle>
							</div>

							<PopupFooter>
								<Button
									type='button'
									isSuccess
									onClick={closePopup}
									text='Ок'
								/>
							</PopupFooter>
						</>
					))}
			</div>
		</Modal>
	)
}
