import clsx from 'clsx'
import { Logo } from 'components/logo'
import { useActionAuth } from 'features/auth/api/useActionAuth'
import { FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { TAuth } from 'shared/services/types/auth.type'
import { Button, FormWrapper, Input } from 'shared/ui/'
import { addMessageToast } from 'shared/utils/addMessageToast'
import styles from './login.module.scss'

export const Login = () => {
	const navigate = useNavigate()
	const { loginUser } = useActionAuth()
	const methods = useForm({ mode: 'onBlur' })
	const onSubmit = async (data: FieldValues) => {
		try {
			const loginData = data as TAuth.Login
			await loginUser.mutateAsync(loginData)
			navigate('/profile')
		} catch (error) {
			addMessageToast('error reg')
		}
	}

	return (
		<div className={clsx(styles.login)}>
			<Logo />
			<FormWrapper
				className={clsx(styles.form, 'form')}
				methods={methods}
				onSubmit={onSubmit}
			>
				<Input
					placeholder='Логин'
					control={methods.control}
					name='login'
					type='text'
					errors={methods.formState.errors}
					rules={{ required: 'обязательное поле' }}
				/>

				<Input
					placeholder='Пароль'
					control={methods.control}
					name='password'
					type='password'
					errors={methods.formState.errors}
					rules={{ required: 'обязательное поле' }}
				/>
				<Button type='submit' text='Логин' />
			</FormWrapper>
		</div>
	)
}
