import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from './item-link.module.scss'

interface IProps {
	isActive: boolean
	icon: JSX.Element
	name: string
	url: string
}

export const ItemLink: FC<IProps> = ({ isActive, icon, name, url }) => {
	return (
		<Link
			className={clsx(isActive && styles.itemIsOpen, styles.item, 'item')}
			to={url}
		>
			<span className={clsx(styles.icon)}>{icon}</span>
			<span className={clsx(styles.text, 'text')}>{name}</span>
		</Link>
	)
}
