import clsx from 'clsx'
import React from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	FieldValues,
	RegisterOptions,
} from 'react-hook-form'
import styles from './input.module.scss'

interface IProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	control: Control<FieldValues>
	name: string
	rules: RegisterOptions<FieldValues, string>
	errors?: FieldErrors<FieldValues>
	labelInner?: string
	isTextArea?: string
}

export const Input: React.FC<IProps> = ({
	control,
	name,
	type,
	pattern,
	placeholder,
	rules,
	disabled,
	errors,
	...props
}) => {
	const error = errors?.[name]?.message?.toString()

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<div
					className={clsx(
						error && styles.input__error,
						styles.input__wrapper,
						'input--wrapper'
					)}
				>
					<input
						type={type}
						id={name}
						value={field.value}
						onChange={field.onChange}
						pattern={pattern}
						placeholder={placeholder}
						className={clsx(styles.input, error && styles.error, 'input')}
						disabled={disabled}

						{...props}
					/>
					{error && <span>{error}</span>}
				</div>
			)}
		/>
	)
}
