import clsx from 'clsx'
import { FC } from 'react'
import { BarGraphIcon } from 'shared/assets/icons'
import { roundNumber } from 'shared/utils/roundNumber'

interface IProps {
	descr: string
	percent: number
	count: string | number
	isLight?: boolean
	isLoading?: boolean
}

export const BarItem: FC<IProps> = ({
	descr,
	percent,
	count,
	isLight,
	isLoading
}) => {
	return (
		<div className={clsx('bar__item', isLight && 'light')}>
			{isLoading && <div className={'loading'} />}
			<div className='bar__row'>
				<span className='bar__descr'>{descr || 'NaN'}</span>
				{!isLight && (
					<span
						className={clsx('bar__badge', percent < 0 && 'bar__badge-down')}
					>
						%{roundNumber(Math.abs(percent))|| '0'}
					</span>
				)}
			</div>
			<div className='bar__row'>
				{isLight && <BarGraphIcon />}
				<span className='bar__title'>{count}</span>
			</div>
		</div>
	)
}
