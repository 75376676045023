import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
// import statisticGraph from './statistic-graph.png'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import { Layout } from 'shared/ui/layout'
// import LineChart from '../../Components/LineChart/LineChart.jsx'
import { ChartRange } from 'entities/linechart/chart-range'
import {
	useGetSequentialStatistics,
	useGetStatistic
} from 'features/statistic/api'
import { Bar } from 'features/statistic/bar/'
import { BarItem } from 'features/statistic/bar/bar-item'
import { LineChart } from 'features/statistic/linechart/'
import { getDateRange } from 'shared/hooks/useGetDateRange'
import { TStatistics } from 'shared/services/types/statistic.type'
import { PageTitle } from 'widgets/page-title/'
import styles from './statistic.module.scss'
import { addDays, format, subYears } from 'date-fns'

Chart.register(CategoryScale)
Chart.defaults.font.family = 'Roboto'

const beforeYesterday = getDateRange('beforeYesterday')
const yesterday = getDateRange('yesterday')
const today = getDateRange('today')
const beforeWeek = getDateRange('beforeWeek')
const week = getDateRange('week')
const beforeMonth = getDateRange('beforeMonth')
const month = getDateRange('month')

const dates = {
	beforeYesterday,
	yesterday,
	today,
	beforeWeek,
	week,
	beforeMonth,
	month
} as const

export type TDatesStat = typeof dates

export type TChartLabel = {
	date: string
	stat: string
}

const defaultValueCalendar = [
	subYears(new Date(), 1),
	addDays(new Date(), 1) 
] as TStatistics.Value

export const Statistic = () => {
	const [dateCalendar, setDateCalendar] =
		useState<TStatistics.Value>(defaultValueCalendar)

	const [filter, setFilter] = useState<TStatistics.GetItem>({
		date_to: Array.isArray(dateCalendar)
			? format(dateCalendar[1]!, 'yyyy-MM-dd')
			: '',
		date_from: Array.isArray(dateCalendar)
			? format(dateCalendar[0]!, 'yyyy-MM-dd')
			: ''
	})

	const dataStatistics = useGetSequentialStatistics(dates)

	const { data: dataStatisticChart, isLoading } = useGetStatistic(filter)

	const [chartLabelsData, setChartLabelsData] = useState<TChartLabel[]>([])

	useEffect(() => {
		const result = Object.entries(dataStatisticChart || {}).map(
			([key, value]) => {
				return {
					date: format(new Date(`${key}`).toISOString(), 'MMM yyyy'),
					stat: `${value}`
				}
			}
		)
		setChartLabelsData(result)
	}, [dataStatisticChart])

	const [chartData, setChartData] = useState({
		labels: chartLabelsData.map(data => data.date),
		datasets: [
			{
				label: '',
				data: chartLabelsData.map(data => data.stat),
				backgroundColor: ['#ecf0f1'],
				borderColor: '#347AE2',
				borderWidth: 2,
				pointStyle: 'circle',
				pointRadius: 1,
				pointHoverRadius: 10
			}
		]
	})

	useEffect(() => {
		setChartData({
			labels: chartLabelsData.map(data => data.date),
			datasets: [
				{
					label: '',
					data: chartLabelsData.map(data => data.stat),
					backgroundColor: ['#ecf0f1'],
					borderColor: '#347AE2',
					borderWidth: 2,
					pointStyle: 'circle',
					pointRadius: 1,
					pointHoverRadius: 10
				}
			]
		})
	}, [chartLabelsData])

	useEffect(() => {
		if (!Array.isArray(dateCalendar)) {
			return
		}
		setFilter({
			date_from: format(dateCalendar[0]!, 'yyyy-MM-dd'),
			date_to: format(dateCalendar[1]!, 'yyyy-MM-dd')
		})
	}, [dateCalendar])

	const totalStatistics = useMemo(() => {
		const result = Object.values(dataStatisticChart || {}).reduce(
			(acc: number, count: any) => {
				return acc + count
			},
			0
		)
		return result
	}, [dataStatisticChart])

	return (
		<Layout>
			<div className={clsx(styles.pageHeader, 'pageHeader')}>
				<PageTitle className={clsx(styles.title)}>
					Количество Посещении
				</PageTitle>
				<Bar>
					<BarItem
						descr='Сегодня'
						percent={+'10'}
						isLoading={true}
						count='33'
					/>
					<BarItem
						descr='За неделю'
						percent={+'10'}
						isLoading={true}
						count='134'
					/>
					<BarItem
						descr='Месяц'
						percent={+'10'}
						isLoading={true}
						count='2,928'
					/>
					<BarItem
						descr='Всего'
						percent={+'10'}
						isLoading={true}
						count='4,765'
						isLight
					/>
				</Bar>
			</div>
			<div className={clsx(styles.pageContent, 'pageContent')}>
				<PageTitle className={clsx(styles.title)}>Заявки</PageTitle>
				<LineChart chartData={chartData} isLoading={isLoading}>
					<ChartRange
						dateCalendar={dateCalendar}
						setDateCalendar={setDateCalendar}
					/>
				</LineChart>
			</div>
			<footer className={clsx(styles.pageFooter, 'pageFooter')}>
				<PageTitle className={clsx(styles.title)}>Количество Заявок</PageTitle>
				<Bar>
					<BarItem
						descr='ВЧЕРА'
						percent={dataStatistics.yesterday.diffPercent}
						count={dataStatistics.yesterday.data}
						isLoading={dataStatistics.yesterday.isLoading}
					/>
					<BarItem
						descr='СЕГОДНЯ'
						percent={dataStatistics.today.diffPercent}
						isLoading={dataStatistics.today.isLoading}
						count={dataStatistics.today.data}
					/>
					<BarItem
						descr='За неделю'
						percent={dataStatistics.week.diffPercent}
						isLoading={dataStatistics.week.isLoading}
						count={dataStatistics.week.data}
					/>
					<BarItem
						descr='Месяц'
						percent={dataStatistics.month.diffPercent}
						count={dataStatistics.month.data}
						isLoading={dataStatistics.month.isLoading}
					/>
					<BarItem
						descr='Всего'
						percent={10}
						count={totalStatistics}
						isLoading={dataStatistics.all.isLoading}
						isLight
					/>
				</Bar>
			</footer>
		</Layout>
	)
}
