import clsx from 'clsx'
import { FC } from 'react'
import {
	AddIcon,
	ArchiveIcon,
	PagingArrIcon,
	RestoreIcon,
} from 'shared/assets/icons'
import styles from './button.module.scss'

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	icon?: string
	text: string
	isSuccess?: boolean
	isLight?: boolean
	isDanger?: boolean
	onClick?: any
}

export const Button: FC<IProps> = ({
	icon,
	type,
	text,
	isSuccess,
	className,
	isLight,
	isDanger,
	onClick,
	disabled,
	...props
}) => {
	return (
		<button
			className={clsx(
				className,
				styles.btn,
				isSuccess && 'success',
				isLight && 'light',
				isDanger && 'danger',
				disabled && 'disabled',
				'btn'
			)}
			type={type}
			onClick={onClick}
			disabled={disabled}
			{...props}
		>
			{icon === 'prev' && <PagingArrIcon />}
			{icon === 'next' && <PagingArrIcon />}
			{icon === 'add' && <AddIcon />}
			{icon === 'archive' && <ArchiveIcon />}
			{icon === 'restore' && <RestoreIcon />}
			<span className={clsx(styles.content, !icon && styles.single)}>
				{text}
			</span>
		</button>
	)
}
