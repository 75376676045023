import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css'
import clsx from 'clsx'
import { TableFilter } from 'entities/table-filter'
import React, { FC, PropsWithChildren } from 'react'
import 'react-calendar/dist/Calendar.css'
import { Checkbox } from 'shared/ui'

interface IProps extends PropsWithChildren {
	data?: any
	isFilter?: boolean
	isCheckbox?: boolean
	isCalendar?: boolean
	children?: any
	items?: any
	checkedItem?: boolean
	setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
	onChangeCheckbox?: VoidFunction
	onFilterHandle?: (key: string, value: any) => void
}

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export const TableCell: FC<IProps> = ({
	data,
	isFilter,
	isCheckbox,
	isCalendar,
	children,
	checkedItem,
	onChangeCheckbox,
	onFilterHandle,
	...props
}) => {
	const [isOpen, setIsOpen] = React.useState(false)

	const toggle = () => setIsOpen(!isOpen)
	return (
		<div className={clsx('table__cell')}>
			{isCheckbox && (
				<Checkbox isChecked={checkedItem} onChange={onChangeCheckbox} />
			)}
			<span>{children}</span>
			{(isFilter || isCalendar) && (
				<TableFilter
					onClick={toggle}
					isOpen={isOpen}
					data={data}
					isFilter={isFilter}
					isCalendar={isCalendar}
					setIsOpen={setIsOpen}
					onFilterHandle={onFilterHandle}
				/>
			)}
		</div>
	)
}
