import clsx from 'clsx'
import { FC } from 'react'
import { Button } from 'shared/ui'
import styles from './table-pagination.module.scss'

interface IProps {
	onPageChange: (offset: number) => void
	total: number
	limit: number
	offset: number
}

export const TablePagination: FC<IProps> = ({
	onPageChange,
	total,
	limit,
	offset
}) => {
	const minLimitPage =
		(offset * limit || 1) > total ? total : offset * limit || 1

	const maxLimitPage =
		((offset + 1) * limit || 1) > total ? total : (offset + 1) * limit || 1

	const handlePrevClick = () => {
		onPageChange(offset - 1)
	}

	const handleNextClick = () => {
		onPageChange(offset + 1)
	}

	return (
		<div className={clsx(styles.wrapper, 'wrapper')}>
			<p className={clsx(styles.text, 'text')}>
				<span className={clsx(styles.pages, 'pages')}>
					{offset * limit || 1} - {maxLimitPage}
				</span>{' '}
				из {total}
			</p>
			<Button
				onClick={() => handlePrevClick()}
				disabled={[0, 1].includes(minLimitPage)}
				className={clsx(styles.prev)}
				icon='prev'
				isLight
				text=''
			/>
			<Button
				onClick={() => handleNextClick()}
				className={clsx(styles.next)}
				disabled={maxLimitPage >= total}
				icon='next'
				isLight
				text=''
			/>
		</div>
	)
}
