import { format } from 'date-fns'

const {
	subDays,
	subWeeks,
	subMonths,
	startOfToday,
	startOfDay,
	startOfWeek,
	startOfMonth,
	endOfDay,
	endOfWeek,
	endOfMonth
} = require('date-fns')

// Функция для получения объекта с датами за определенный период
export function getDateRange(period: string) {
	let date_to: string | undefined, date_from: string | undefined

	switch (period) {
		case 'beforeYesterday':
			date_to = endOfDay(subDays(startOfToday(), 2)).toISOString()
			date_from = startOfDay(subDays(startOfToday(), 2)).toISOString()
			break
		case 'yesterday':
			date_to = endOfDay(subDays(startOfToday(), 1)).toISOString()
			date_from = startOfDay(subDays(startOfToday(), 1)).toISOString()
			break
		case 'today':
			date_to = endOfDay(startOfToday()).toISOString()
			date_from = startOfDay(startOfToday()).toISOString()
			break
		case 'beforeWeek':
			date_to = endOfDay(subDays(startOfWeek(startOfToday()), 1)).toISOString()
			date_from = startOfWeek(
				subDays(startOfWeek(startOfToday()), 1)
			).toISOString()
			break
		case 'week':
			date_to = endOfDay(startOfToday()).toISOString()
			date_from = startOfWeek(startOfToday()).toISOString()
			break

		case 'beforeMonth':
			date_to = endOfDay(
				subMonths(startOfMonth(startOfToday()), 1)
			).toISOString()
			date_from = startOfMonth(
				subMonths(startOfMonth(startOfToday()), 1)
			).toISOString()
			break
		case 'month':
			date_to = endOfDay(startOfToday()).toISOString()
			date_from = startOfMonth(startOfToday()).toISOString()
			break
		default:
			date_to = undefined
			date_from = undefined
			break
	}

	return {
		date_to: date_to ? format(date_to, 'yyyy-MM-dd') : undefined,
		date_from: date_from ? format(date_from, 'yyyy-MM-dd') : undefined
	}
}
