import clsx from 'clsx'
import { ProfileItem } from 'features/profile/profile-item'
import { useActionsUsers, useGetCurrentUser } from 'features/users/api'
import { useEffect } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { RoleOptions } from 'shared/constants'
import { TUsers } from 'shared/services/types/users.type'
import { Input } from 'shared/ui/'
import { FormSelect } from 'shared/ui/form-select'
import { Label } from 'shared/ui/label'
import { Layout } from 'shared/ui/layout/'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { PageTitle } from 'widgets/page-title'
import styles from './profile.module.scss'

export const Profile = () => {
	const { changePassword } = useActionsUsers()
	const methods = useForm({ mode: 'onBlur' })
	const methods2 = useForm({ mode: 'onBlur' })

	const onChangePassword = async (data: FieldValues) => {
		const result = data as TUsers.ChangePassword

		try {
			await changePassword.mutateAsync(result)
		} catch (error: any) {
			addMessageToast('Error')
		}
	}

	const onChangeUserIfon = async (data: FieldValues) => {
		const result = data as TUsers.ChangePassword

		try {
			await changePassword.mutateAsync(result)
		} catch (error: any) {
			addMessageToast('Error')
		}
	}

	const onChangePassword2 = async (data: FieldValues) => {}

	const { data } = useGetCurrentUser()

	useEffect(() => {
		const role = RoleOptions.find(item => item.value === data?.role)
		methods2.reset({ role: role, login: data?.login })
	}, [data])

	return (
		<Layout>
			<div className='pageHeader'>
				<PageTitle>Профиль</PageTitle>
			</div>
			<div className={clsx(styles.pageContent, 'pageContent')}>
				<div className={clsx(styles.items, 'profile__items')}>
					<ProfileItem
						onSubmit={onChangePassword2}
						methods={methods2}
						title='Инфо'
						formID='changeUserForm'
						disabled
					>
						<Label
							className={clsx(styles.label, 'form__label')}
							htmlFor='type'
							labelText='Тип'
						>
							<FormSelect
								placeholder='Тип'
								options={RoleOptions}
								control={methods2.control}
								name='role'
								disabled={data?.role === 'operator' || true}
								errors={methods2.formState.errors}
								rules={{ required: 'обязательное поле' }}
							/>
						</Label>

						<Label
							className={clsx('form__label')}
							htmlFor='login'
							labelText='Логин'
						>
							<Input
								placeholder=''
								control={methods2.control}
								name='login'
								type='text'
								disabled
								errors={methods2.formState.errors}
								rules={{ required: 'обязательное поле' }}
							/>
						</Label>
					</ProfileItem>
					<ProfileItem
						onSubmit={onChangePassword}
						methods={methods}
						title='Обновить пароль'
						formID='changePassForm'
					>
						<Label
							className={clsx('form__label')}
							htmlFor='old_password'
							labelText='Текущий пароль'
						>
							<Input
								placeholder='********'
								control={methods.control}
								name='old_password'
								type='password'
								errors={methods.formState.errors}
								rules={{ required: 'обязательное поле' }}
							/>
						</Label>

						<Label
							className={clsx('form__label')}
							htmlFor='new_password'
							labelText='Новый пароль'
						>
							<Input
								placeholder='********'
								control={methods.control}
								name='new_password'
								type='password'
								errors={methods.formState.errors}
								rules={{ required: 'обязательное поле' }}
							/>
						</Label>

						<Label
							className={clsx('form__label')}
							htmlFor='confirm_password'
							labelText='Подтвердите пароль'
						>
							<Input
								placeholder='********'
								control={methods.control}
								name='confirm_password'
								type='password'
								errors={methods.formState.errors}
								rules={{ required: 'обязательное поле' }}
							/>
						</Label>
					</ProfileItem>
				</div>
			</div>
		</Layout>
	)
}
