import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { TDatesStat } from 'pages/statistic'
import { useEffect, useState } from 'react'
import { StatisticService } from 'shared/services/statistic.service'
import { TStatistics } from 'shared/services/types/statistic.type'

export const useGetSequentialStatistics = (dates?: TDatesStat) => {
	const [prevYesterdayLoad, setPrevYesterdayLoad] = useState(false)
	const [yesterdayLoad, setYesterdayLoad] = useState(false)
	const [todayLoad, setTodayLoad] = useState(false)
	const [prevWeekLoad, setPrevWeekLoad] = useState(false)
	const [weekLoad, setWeekLoad] = useState(false)
	const [prevMonthLoad, setPrevMonthLoad] = useState(false)
	const [monthLoad, setMonthLoad] = useState(false)

	const prevYesterday = useQuery({
		queryFn: () => StatisticService.getItem(dates?.beforeYesterday),
		queryKey: ['get stat', dates?.beforeYesterday],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data
	})

	const yesterday = useQuery({
		queryFn: () => StatisticService.getItem(dates?.yesterday),
		queryKey: ['get stat', dates?.yesterday],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!prevYesterdayLoad
	})

	const today = useQuery({
		queryFn: () => StatisticService.getItem(dates?.today),
		queryKey: ['get stat', dates?.today],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!yesterdayLoad
	})

	const prevWeek = useQuery({
		queryFn: () => StatisticService.getItem(dates?.beforeWeek),
		queryKey: ['get stat', dates?.beforeWeek],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!todayLoad
	})

	const week = useQuery({
		queryFn: () => StatisticService.getItem(dates?.week),
		queryKey: ['get stat', dates?.week],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!prevWeekLoad
	})

	const prevMonth = useQuery({
		queryFn: () => StatisticService.getItem(dates?.beforeMonth),
		queryKey: ['get stat', dates?.beforeMonth],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!weekLoad
	})

	const month = useQuery({
		queryFn: () => StatisticService.getItem(dates?.month),
		queryKey: ['get stat', dates?.month],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!prevMonthLoad
	})

	const all = useQuery({
		queryFn: () => StatisticService.getItem(),
		queryKey: ['get stat all'],
		retry: 0,
		staleTime: Infinity,
		select: ({ data }) => data,
		enabled: !!monthLoad
	})

	useEffect(() => {
		setPrevYesterdayLoad(prevYesterday.isSuccess)
	}, [prevYesterday.isSuccess])

	useEffect(() => {
		setYesterdayLoad(yesterday.isSuccess)
	}, [yesterday.isSuccess])

	useEffect(() => {
		setTodayLoad(today.isSuccess)
	}, [today.isSuccess])

	useEffect(() => {
		setPrevWeekLoad(prevWeek.isSuccess)
	}, [prevWeek.isSuccess])

	useEffect(() => {
		setWeekLoad(week.isSuccess)
	}, [week.isSuccess])

	useEffect(() => {
		setPrevMonthLoad(prevMonth.isSuccess)
	}, [prevMonth.isSuccess])

	useEffect(() => {
		setMonthLoad(month.isSuccess)
	}, [month.isSuccess])

	const getStat = (
		prevData: UseQueryResult<TStatistics.SuccessResponse, Error>,
		newData: UseQueryResult<TStatistics.SuccessResponse, Error>,
		isLoading?: boolean
	) => {
		const prev = prevData?.data?.data?.statistics || 0
		const current = newData?.data?.data?.statistics || 0
		const diff = current - prev

		return {
			data: current,
			diffPercent: (diff / (current || 1)) * 100,
			isLoading: prevData?.isLoading || newData.isLoading || isLoading
		}
	}

	const result = {
		yesterday: getStat(
			prevYesterday,
			yesterday,
			!prevYesterdayLoad || !yesterdayLoad
		),
		today: getStat(yesterday, today, !yesterdayLoad || !todayLoad),
		week: getStat(prevWeek, week, !prevWeekLoad || !weekLoad),
		month: getStat(prevMonth, month, !prevMonthLoad),
		all: getStat(prevMonth, month, !monthLoad)
	}

	return result
}
