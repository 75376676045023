import { countries } from './countries'

export const COLUMNS = [
	{
		Header: 'ID',
		accessor: 'idNumber',
		isCheckbox: true
	},
	{
		Header: 'Тип',
		accessor: 'type'
	},
	{
		Header: 'Имя',
		accessor: 'name'
	},
	{
		Header: 'Комментарий',
		accessor: 'comment'
	},
	{
		Header: 'Страна',
		accessor: 'country',
		isFilter: true,
		items: countries
	},
	{
		Header: 'Почта',
		accessor: 'email'
	},
	{
		Header: 'Телефон',
		accessor: 'phone'
	},
	{
		Header: 'Дата',
		accessor: 'date',
		isCalendar: true
	},
	{
		Header: 'Статус',
		accessor: 'status',
		isFilter: true,
		items: [
			{ label: 'isArchive', value: 'grey' },
			{ label: 'isAgent', value: 'orange' },
			{ label: 'isActive', value: 'blue' }
		]
	},
	{
		Header: '',
		accessor: 'action'
	}
]
