import clsx from 'clsx'
import { FC } from 'react'
import {
	Control,
	Controller,
	FieldErrors,
	FieldValues,
	RegisterOptions
} from 'react-hook-form'
import ReactSelect from 'react-select'
import styles from './form-select.module.scss'

export type TMyOptions = {
	value: string | number
	label: string
}

interface IProps {
	control: Control<FieldValues>
	name: string
	rules?: RegisterOptions<FieldValues, string>
	errors?: FieldErrors<FieldValues>
	placeholder: string
	options: TMyOptions[]
	disabled?: boolean
}
export const FormSelect: FC<IProps> = ({
	name,
	control,
	rules,
	placeholder,
	options,
	errors,
	disabled
}) => {

	const error = errors?.[name]?.message?.toString()

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ field }) => (
				<div
				className={clsx(
					styles.wrapper,
					error && styles.error,
				)}
			>
				<ReactSelect
					placeholder={placeholder}
					className={clsx('form__select', styles.select)}
					classNamePrefix='react-select'
					options={options}
					value={field.value}
					isDisabled={disabled}
					onChange={field.onChange}
				/>
						{error && <span>{error}</span>}
				</div>
			)}
		/>
	)
}
