import { useEffect, useRef, useState } from 'react';

type UseVisibleCountResult = {
  ref: React.RefObject<any>;
  count: number;
};

export function useCountTable(elementHeight: number): UseVisibleCountResult {
  const [count, setCount] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateCount = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        const footerHeight = 52;
        const pagination = 55;
        const availableHeight =
          window.innerHeight - rect.top - footerHeight - pagination - 80;

        const visibleCount = Math.floor(availableHeight / elementHeight);
        setCount(visibleCount);
      }
    };

    // Initial calculation
    calculateCount();

    // Recalculate on window resize
    window.addEventListener('resize', calculateCount);

    return () => {
      window.removeEventListener('resize', calculateCount);
    };
  }, [elementHeight]);

  return { ref,  count };
}
