//@ts-nocheck
import clsx from 'clsx'
import { ApplicationDetails } from 'components/application-details'
import { format } from 'date-fns'
import { TableCell } from 'entities/table-cell'
import { TableHead } from 'entities/table-head'
import { TablePagination } from 'entities/table-pagination'
import { TableRow } from 'entities/table-row'
import {
	useActionsApplications,
	useGetApplicationsList,
} from 'features/applications/api'
import { ModalApplications } from 'features/applications/modal-applications'
import { useEffect, useMemo, useState } from 'react'
import { EyeIcon, RestoreIcon, TrashIcon } from 'shared/assets/icons/'
import { useCountTable } from 'shared/hooks/useCountTable'
import { TApplications } from 'shared/services/types/applications.type'
import { Layout, Status } from 'shared/ui/'
import { Loader } from 'shared/ui/loader'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { PageTitle } from 'widgets/page-title'
import { Table } from 'widgets/table'
import styles from './applications.module.scss'
import { COLUMNS } from './columns'

const defaultFilter = {
	offset: 0,
	limit: 0,
	order_by: 'country',
	is_desc_sort: true,
} as TApplications.GetList

export const Applications = () => {
	const { count, ref } = useCountTable(55)
	const [currentData, setCurrentData] = useState(null)

	const { deleteApplication, updateApplication } = useActionsApplications(
		currentData?.id
	)
	const [filter, setFilter] = useState<TApplications.GetList>(defaultFilter)
	const { data: applicationsData, isLoading } = useGetApplicationsList(filter)
	const columns = useMemo(() => COLUMNS, [])
	const [total, setTotal] = useState(0)

	const [isAddInfoOpen, setIsAddInfoOpen] = useState(false)

	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [popupStep, setPopupStep] = useState(2)

	useEffect(() => {
		if (applicationsData) {
			setTotal(applicationsData.total)
		}
	}, [applicationsData?.total])

	useEffect(() => {
		if (count) {
			setFilter(prev => ({ ...prev, limit: count }))
		}
	}, [count])

	const onFilterHandle = (key: string, value: any) => {
		if (key === 'data') {
			const newFilter = {
				...filter,
				date_from: format(value[0], 'yyyy-MM-dd'),
				date_to: format(value[1], 'yyyy-MM-dd'),
			}
			setFilter(newFilter)
		} else {
			const newFilter = { ...filter, [key]: value }
			setFilter(newFilter)
		}
	}

	// Popup

	const openPopup = (data: any) => {
		setCurrentData(data)
		setPopupStep(data.status === 'grey' ? 1 : 0)
		setIsPopupOpen(true)
	}

	const closePopup = () => {
		setCurrentData(null)
		setIsPopupOpen(false)
		setPopupStep(0)
		closeAddInfo()
	}

	const openAddInfo = async (data: any) => {
		try {
			setCurrentData(data)
			setIsAddInfoOpen(true)
		} catch (error) {
			addMessageToast('Error')
		}
	}

	const closeAddInfo = () => {
		setCurrentData(null)
		setIsAddInfoOpen(false)
	}

	const deleteItem = (data: any) => {
		try {
			closePopup()
		} catch (error) {
			addMessageToast('Ошибка, попробуйте позже!')
		}
	}

	const toggleStatus = async () => {
		try {
			await updateApplication.mutateAsync({
				status: currentData.status,
			})
			setPopupStep(2)
		} catch (error) {
			addMessageToast('Ошибка, попробуйте позже!')
		}
	}

	const [checkedItems, setCheckedItems] = useState<number[]>([])
	const [checkedAll, setCheckedAll] = useState(false)

	const onChangeAllCheckbox = (ids: number[]) => {
		if (checkedItems.length) {
			setCheckedItems([])
			setCheckedAll(false)
		} else {
			setCheckedItems(ids)
			setCheckedAll(true)
		}
	}

	const onChangeCheckbox = (id: number) => {
		if (checkedItems.includes(id)) {
			const result = checkedItems.filter(item => item !== id)
			setCheckedItems(result)
		} else {
			setCheckedItems([...checkedItems, id])
		}
	}

	return (
		<Layout>
			<div className={clsx(styles.pageHeader, 'pageHeader')}>
				<PageTitle className={clsx(styles.title)}>Заявки</PageTitle>
				<a className='pageHeader__link' href='#'>
					Скачать XLSX
				</a>
				<ModalApplications
					isPopupOpen={isPopupOpen}
					popupStep={popupStep}
					setPopupStep={setPopupStep}
					onClose={closePopup}
					toggleStatus={toggleStatus}
				/>
			</div>
			<div className={clsx(styles.pageContent, 'pageContent')} ref={ref}>
				<Table className='requests__table'>
					<TableHead>
						{columns.map(column => (
							<TableCell
								key={column.Header}
								isCheckbox={column.isCheckbox}
								isFilter={column.isFilter}
								isCalendar={column.isCalendar}
								data={column}
								checkedItem={checkedAll}
								onFilterHandle={onFilterHandle}
								onChangeCheckbox={() => {
									onChangeAllCheckbox(applicationsData?.items?.map(item => item.id))
								}}
							>
								{column.Header}
							</TableCell>
						))}
					</TableHead>
					{applicationsData?.items?.map((row, index) => {
						return (
							<TableRow>
								<TableCell
									isCheckbox
									checkedItem={checkedItems.includes(row.id)}
									onChangeCheckbox={() => {
										onChangeCheckbox(row.id)
									}}
								>
									{row.id}
								</TableCell>
								<TableCell>{row.application_type}</TableCell>
								<TableCell>{row.full_name}</TableCell>
								<TableCell>{row.commentary}</TableCell>
								<TableCell>{row.country}</TableCell>
								<TableCell>{row.email}</TableCell>
								<TableCell>{row.phone}</TableCell>
								<TableCell>{row.created_at}</TableCell>
								<TableCell>
									<Status
										isArchive={row.status === 'grey'}
										isActive={row.status === 'blue'}
										isAgent={row.status === 'orange'}
									/>
								</TableCell>
								<TableCell>
									{row.status === 'grey' ? (
										<RestoreIcon
											onClick={() => {
												openPopup(row)
											}}
										/>
									) : (
										<TrashIcon
											onClick={() => {
												openPopup(row)
											}}
										/>
									)}

									<EyeIcon
										onClick={() => {
											openAddInfo(row)
										}}
									/>
								</TableCell>
							</TableRow>
						)
					})}
				</Table>
				{isLoading && <Loader />}
			</div>
			<TablePagination
				total={total}
				limit={filter?.limit}
				offset={filter?.offset}
				onPageChange={(page: any) => {
					onFilterHandle('offset', page)
				}}
			/>
			{isAddInfoOpen && (
				<ApplicationDetails
					onClose={closeAddInfo}
					data={currentData}
					openPopup={openPopup}
				/>
			)}
		</Layout>
	)
}
