import { useQuery } from '@tanstack/react-query'
import { ApplicationsService } from 'shared/services/applications.service'
import { TApplications } from 'shared/services/types/applications.type'

export const useGetApplicationsList = (data: TApplications.GetList) => {
	const getApplicationsList = useQuery({
		queryFn: () => ApplicationsService.getList(data),
		queryKey: ['get applications list', data],
		retry: 0,
		enabled: !!data.date_from && !!data.limit,
		select: ({ data }) => data
	})
	return getApplicationsList
}
