import clsx from 'clsx'
import { format } from 'date-fns'
import { FC, useState } from 'react'
import Calendar from 'react-calendar'
import useOutsideClick from 'shared/hooks/useOutsideClick'
import { TStatistics } from 'shared/services/types/statistic.type'
import { Button } from 'shared/ui'
import styles from './chart-range.module.scss'

interface IProps {
	dateCalendar: TStatistics.Value
	setDateCalendar: React.Dispatch<React.SetStateAction<TStatistics.Value>>
}

export const ChartRange: FC<IProps> = ({ dateCalendar, setDateCalendar }) => {
	const [dates, setDates] = useState(dateCalendar)
	const [isOpen, setIsOpen] = useState(false)
	const { ref } = useOutsideClick(setIsOpen)

	const startDate =
		Array.isArray(dateCalendar) && dateCalendar?.[0]
			? dateCalendar?.[0]
			: new Date()

	const endDate =
		Array.isArray(dateCalendar) && dateCalendar?.[1]
			? dateCalendar?.[1]
			: new Date()

	const tooglePopup = () => {
		setIsOpen(!isOpen)
	}

	const onSetDate = () => {
		setDateCalendar(dates)
		setIsOpen(false)
	}

	return (
		<div className={clsx(styles.wrapper)} ref={ref}>
			<div className={clsx(styles.items)}>
				<div className={clsx(styles.item)}>
					<p className={clsx(styles.text)}>
						from
						<span className={clsx(styles.date)} onClick={tooglePopup}>
							{format(startDate, 'MMMM yyyy')}
						</span>
						{isOpen && (
							<div className='calendar-wrapper'>
								<Calendar
									selectRange={true}
									onChange={setDates}
									value={dates}
									maxDetail='year'
									maxDate={new Date()}
									// minDetail='year'
								/>
								<Button text='Set Date' onClick={onSetDate} />
							</div>
						)}
					</p>
				</div>
				<div className={clsx(styles.item)}>
					<p className={clsx(styles.text)}>
						to
						<span className={clsx(styles.date)} onClick={tooglePopup}>
							{format(endDate, 'MMMM yyyy')}
						</span>
					</p>
				</div>
			</div>
		</div>
	)
}
