import clsx from 'clsx'
import { TableCell } from 'entities/table-cell'
import { TableHead } from 'entities/table-head'
import { TablePagination } from 'entities/table-pagination'
import { TableRow } from 'entities/table-row'
import { useActionsLandings, useGetLandingsList } from 'features/landings/api'
import { ModalLendings } from 'features/landings/modal-landings'
import { useEffect, useMemo, useState } from 'react'
import { EditIcon, TrashIcon } from 'shared/assets/icons'
import { TLandings } from 'shared/services/types/landings.type'
import { Button } from 'shared/ui'
import { Layout } from 'shared/ui/layout'
import { Loader } from 'shared/ui/loader'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { PageTitle } from 'widgets/page-title'
import { Table } from 'widgets/table'
import { COLUMNS } from './columns'
import styles from './landings.module.scss'
import { useCountTable } from 'shared/hooks/useCountTable'

const defaultFilter = {
	offset: 0,
	limit: 0,
	order_by: 'id',
	is_desc_sort: true
} as TLandings.GetList

export const Landings = () => {
	const {count,ref} = useCountTable(55)

	const { createLanding, updateLanding, deleteLanding } = useActionsLandings()
	const [filter, setFilter] = useState<TLandings.GetList>(defaultFilter)
	const { data: landingsData, isLoading } = useGetLandingsList(filter)
	const columns = useMemo(() => COLUMNS, [])
	const [total, setTotal] = useState(0)

	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [popupStep, setPopupStep] = useState(2)
	const [currentData, setCurrentData] = useState<TLandings.Data | null>(null)

	useEffect(() => {
		if (landingsData) {
			setTotal(landingsData.total)
		}
	}, [landingsData?.total])

	useEffect(()=>{
		if(count){
			setFilter(prev=>({...prev, limit:count}))
		}
	},[count])

	const onFilterHandle = (key: string, value: any) => {
		const newFilter = { ...filter, [key]: value }
		setFilter(newFilter)
	}

	// Popup
	const openPopup = (isEditMode?: boolean, data?: any) => {
		setPopupStep(data ? 1 : 2)
		setIsPopupOpen(true)
		if (data) {
			setCurrentData(data)
		}
	}

	const closePopup = () => {
		setIsPopupOpen(false)
		setCurrentData(null)
		setPopupStep(2)
	}

	const openDeletePopup = (data: any) => {
		setPopupStep(0)
		setIsPopupOpen(true)
		setCurrentData(data)
	}

	const deleteItem = async (data: any) => {
		try {
			const landing_id: number = currentData!.id
			await deleteLanding.mutateAsync(landing_id)
			closePopup()
		} catch (error) {
			addMessageToast('Ошибка, попробуйте позже!')
		}
	}

	return (
		<Layout>
			<div className='pageHeader'>
				<PageTitle>Landings</PageTitle>
				<Button icon='add' text='Добавить' onClick={openPopup} />
				<ModalLendings
					isPopupOpen={isPopupOpen}
					popupStep={popupStep}
					data={currentData}
					setPopupStep={setPopupStep}
					onClose={closePopup}
					openPopup={openPopup}
					deleteItem={deleteItem}
				/>
			</div>
			<div className={clsx('pageContent')} ref={ref}>
				<Table className={clsx(styles.table, 'landings__table')}>
					<TableHead>
						{columns.map(column => (
							<TableCell>{column.Header}</TableCell>
						))}
					</TableHead>
					{landingsData?.items
						?.sort((a, b) => (a.is_deleted ? 1 : 0))
						?.map((row, index) => {
							return (
								<TableRow>
									<TableCell>{row.domain_name}</TableCell>
									<TableCell>
										{!row.is_deleted && (
											<>
												<TrashIcon
													onClick={() => {
														openDeletePopup(row)
													}}
												/>
												<EditIcon
													onClick={() => {
														openPopup(true, row)
													}}
												/>
											</>
										)}
									</TableCell>
								</TableRow>
							)
						})}
				</Table>
				{isLoading && <Loader />}
			</div>
			<TablePagination
				total={total}
				limit={filter?.limit}
				offset={filter?.offset}
				onPageChange={(page: number) => {
					onFilterHandle('offset', page)
				}}
			/>
		</Layout>
	)
}
