import clsx from 'clsx'
import { FC } from 'react'

import styles from './loader.module.scss'

interface IProps {
	isAbsolute?: boolean
	isFixed?: boolean
}
export const Loader: FC<IProps> = ({ isAbsolute, isFixed }) => {
	return (
		<div
			className={clsx(
				styles.wrapper,
				isFixed && styles.fixed,
				isAbsolute && styles.absolute
			)}
		>
			<span className={styles.loader}></span>
		</div>
	)
}
