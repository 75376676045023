import clsx from 'clsx'
import { format } from 'date-fns'
import { TableCell } from 'entities/table-cell'
import { TableHead } from 'entities/table-head'
import { TablePagination } from 'entities/table-pagination'
import { TableRow } from 'entities/table-row'
import { useActionsUsers, useGetUsersList } from 'features/users/api'
import { ModalUsers } from 'features/users/modal-users'
import { useEffect, useMemo, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import 'reactjs-popup/dist/index.css'
import { TrashIcon } from 'shared/assets/icons'
import { TUsers } from 'shared/services/types/users.type'
import { Button } from 'shared/ui'
import { Layout } from 'shared/ui/layout'
import { Loader } from 'shared/ui/loader'
import { addMessageToast } from 'shared/utils/addMessageToast'
import { PageTitle } from 'widgets/page-title'
import { Table } from 'widgets/table'
import { COLUMNS } from './columns'
import styles from './users.module.scss'
import { useCountTable } from 'shared/hooks/useCountTable'

const defaultFilter = {
	offset: 0,
	limit: 0,
	order_by: 'role',
	is_desc_sort: true
} as TUsers.GetList

export const Users = () => {
	const {count,ref} = useCountTable(55)
	
	const { deleteItem } = useActionsUsers()
	const [filter, setFilter] = useState<TUsers.GetList>(defaultFilter)
	const { data: dataState, isLoading } = useGetUsersList(filter)
	const columns = useMemo(() => COLUMNS, [])
	const [total, setTotal] = useState(0)

	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const [popupStep, setPopupStep] = useState(2)
	const [currentData, setCurrentData] = useState(null)

	

	useEffect(() => {
		if (dataState) {
			setTotal(dataState.total)
		}
	}, [dataState?.total])


	useEffect(()=>{
		if(count){
			setFilter(prev=>({...prev, limit:count}))
		}
	},[count])

	const onFilterHandle = (key: string, value: any) => {
		const newFilter = { ...filter, [key]: value }
		setFilter(newFilter)
	}

	// Popup

	const openPopup = () => {
		setPopupStep(1)
		setIsPopupOpen(true)
	}

	const closePopup = () => {
		
		setCurrentData(null)
		setPopupStep(0)
		setIsPopupOpen(false)
	}

	const openDeletePopup = (data: any) => {
		setPopupStep(0)
		setIsPopupOpen(true)
		setCurrentData(data)
	}

	const deleteUser = async (user_id: number) => {
		try {
			await deleteItem.mutateAsync(currentData!)
			closePopup()
		} catch (error) {
			addMessageToast('Error')
		}
	}

	const onSubmit = async (data: FieldValues) => {
		try {
			const userData = data as TUsers.Create
		} catch (error) {
			addMessageToast('error reg')
		}
	}

	return (
		<Layout>
			<div className='pageHeader'>
				<PageTitle className={clsx(styles.title)}>Пользователи</PageTitle>
				<Button text='Добавить' icon='add' onClick={openPopup} />
				<ModalUsers
					isPopupOpen={isPopupOpen}
					popupStep={popupStep}
					setPopupStep={setPopupStep}
					onClose={closePopup}
					deleteItem={deleteUser}
				/>
			</div>
			<div className={clsx(styles.pageContent, 'pageContent')} ref={ref}>
				<Table className={clsx(styles.table)}>
					<TableHead>
						{columns.map(column => (
							<TableCell
							  key={column.Header}
								isFilter={column.isFilter}
								data={column}
								onFilterHandle={onFilterHandle}
							>
								{column.Header}
							</TableCell>
						))}
					</TableHead>
					{dataState?.items?.map((row, index) => {
						return (
							<TableRow key={row.id}>
								<TableCell>
									{row.role === 'administrator' ? 'Администратор' : 'Оператор'}
								</TableCell>
								<TableCell>{row.login}</TableCell>
								{/* <TableCell>{row.password}</TableCell> */}
								<TableCell>-</TableCell>
								<TableCell>
									{format(new Date(row.created_at), 'dd/MM/yyyy')}
								</TableCell>
								<TableCell>
									<TrashIcon
										onClick={() => {
											openDeletePopup(row.id)
										}}
									/>
								</TableCell>
							</TableRow>
						)
					})}
				</Table>
				{isLoading && <Loader />}
			</div>
			<TablePagination
				total={total}
				limit={filter?.limit}
				offset={filter?.offset}
				onPageChange={(page: number) => {
					onFilterHandle('offset', page)
				}}
			/>
		</Layout>
	)
}
