import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	className: string
	children: any
}

export const Table: FC<IProps> = ({ className, children }) => {
	return <div className={clsx(className, 'table')}>{children}</div>
}
