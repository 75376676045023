import { useMutation, useQueryClient } from '@tanstack/react-query'
import { LandingsService } from 'shared/services/landings.service'
import { TLandings } from 'shared/services/types/landings.type'

export const useActionsLandings = () => {
	const queryClient = useQueryClient()

	const createLanding = useMutation({
		mutationFn: (data: TLandings.Create) => LandingsService.create(data),
		mutationKey: ['create landing'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['get landings list'] })
		},
	})
	const updateLanding = useMutation({
		mutationFn: (data: { id: number } & TLandings.UpdateItem) => {
			const { id, ...restData } = data
			return LandingsService.update(id, restData)
		},
		mutationKey: ['update landing'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['get landings list'] })
		},
	})

	const deleteLanding = useMutation({
		mutationFn: (landing_id: number) => LandingsService.delete(landing_id),
		mutationKey: ['delete landing'],
		onSuccess: () => {
			console.log('xxxx');
			
			queryClient.invalidateQueries({ queryKey: ['get landings list'] })
		},
	})

	return { createLanding, updateLanding, deleteLanding }
}
