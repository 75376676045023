import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AuthService } from 'shared/services/auth/auth.service'
import { TAuth } from 'shared/services/types/auth.type'

export const useActionAuth = () => {
	const queryClient = useQueryClient()
	const loginUser = useMutation({
		mutationFn: (data: TAuth.Login) => AuthService.login(data),
		mutationKey: ['login user'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['login user'] })
		},
		onMutate(variables) {},
	})

	return { loginUser }
}
