import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'
import { Status } from 'shared/ui'

interface IProps extends PropsWithChildren {
	title: string
	descr?: string
	status?: any
}

export const ApplicationDetailsItem: FC<IProps> = ({
	title,
	descr,
	status,
	children,
}) => {
	return (
		<div className={clsx('ApplicationDetails__item')}>
			<span className={clsx('ApplicationDetails__content--title')}>
				{title}
			</span>
			<p className={clsx('ApplicationDetails__content--descr')}>{children}</p>
			{title === 'Статус' && (
				<Status
					isArchive={status === 'grey'}
					isActive={status === 'blue'}
					isAgent={status === 'orange'}
				/>
			)}
		</div>
	)
}
