export const COLUMNS = [
	{
		Header: 'Роль',
		accessor: 'role',
		isFilter: true,
		items: [
			{ label: 'Админ', value: 'administrator' },
			{ label: 'Оператор', value: 'operator' }
		]
	},
	{
		Header: 'Логин',
		accessor: 'login'
	},
	{
		Header: 'Пароль',
		accessor: 'password'
	},
	{
		Header: 'Дата',
		accessor: 'data'
	},
	{
		Header: 'Действие',
		accessor: 'action'
	}
]
