import { Bounce, toast } from 'react-toastify'

export const addMessageToast = (
	text: string,
	type: 'success' | 'error' | 'info' = 'error'
) => {
	toast[type](text, {
		position: 'bottom-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'light',
		transition: Bounce,
	})
}
