//@ts-nocheck
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import clsx from 'clsx'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import useOutsideClick from 'shared/hooks/useOutsideClick'
import { Button, Status } from 'shared/ui'
import styles from './table-filter.module.scss'
import {subYears ,addDays} from 'date-fns'

interface IProps extends PropsWithChildren {
	data?: any
	isFilter?: boolean
	isOpen?: boolean
	isCalendar?: boolean
	onClick: any
	setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
	onFilterHandle?: (key: string, value: any) => void
}

type ValuePiece = Date | null

type Value = ValuePiece | [ValuePiece, ValuePiece]

export const TableFilter: FC<IProps> = ({
	data,
	isFilter,
	isCalendar,
	isOpen,
	onClick,
	setIsOpen,
	onFilterHandle,
	...props
}) => {
	const { ref } = useOutsideClick(setIsOpen)

	const filterItems = data?.items
	const [dateCalendar, setDateCalendar] = useState<Value>([
		subYears(new Date(), 1),
		addDays(new Date(), 1)
	])

	useEffect(() => {
		onFilterHandle('data', dateCalendar)
	}, [dateCalendar])

	return (
		<div ref={ref} className={clsx(styles.filter)}>
			<div className={clsx(styles.filterCell)} onClick={onClick}></div>
			{isFilter && isOpen && (
				<div className={clsx(styles.filter__list)}>
					{filterItems?.map((item: any) => (
						<span
							onClick={() => {
								onFilterHandle?.(data.accessor, item.value)
								onClick()
							}}
							className={clsx(styles.filter__listItem)}
						>
							{item.label === 'isArchive' ||
							item.label === 'isActive' ||
							item.label === 'isAgent' ? (
								<Status
									isArchive={item.label === 'isArchive'}
									isActive={item.label === 'isActive'}
									isAgent={item.label === 'isAgent'}
								/>
							) : (
								item.label
							)}
						</span>
					))}
				</div>
			)}
			{isCalendar && isOpen && (
				<div className='calendar-wrapper'>
					<Calendar
						selectRange={true}
						onChange={setDateCalendar}
						value={dateCalendar}
					/>
					<DateRangePicker
						disableCalendar={true}
						onChange={setDateCalendar}
						value={dateCalendar}
						clearIcon={null}
					/>
					<Button text='Set Date'  onClick={()=>setIsOpen(false)}/>
				</div>
			)}
		</div>
	)
}
