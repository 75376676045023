import clsx from 'clsx'
import React, { FC, PropsWithChildren } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { Button, FormWrapper } from 'shared/ui'
import styles from './profile-item.module.scss'

interface IProps extends PropsWithChildren {
	children: React.ReactNode
	title: string
	methods: UseFormReturn<FieldValues, any, undefined>
	onSubmit: (data: FieldValues) => void
	formID: string
	disabled?: boolean
}

export const ProfileItem: FC<IProps> = ({
	title,
	children,
	methods,
	onSubmit,
	formID,
	disabled,
}) => {
	return (
		<div className={clsx(styles.item, 'profile__item')}>
			<h3 className={clsx(styles.title, 'profile__title')}>{title}</h3>
			<div className={clsx(styles.wrapper, 'profile__item--wrapper')}>
				<FormWrapper
					className={clsx(styles.form)}
					methods={methods}
					onSubmit={onSubmit}
					id={formID}
				>
					<div className={clsx(styles.form__content, 'form__content')}>
						{children}
					</div>
					<div className={clsx(styles.form__footer, 'form__footer')}>
						<Button type='submit'  disabled={disabled} form={formID} text='Сохранить' icon='' />
					</div>
				</FormWrapper>
			</div>
		</div>
	)
}
