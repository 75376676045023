import { FC, PropsWithChildren } from 'react'
import { Line } from 'react-chartjs-2'
import './linechart.scss'

interface IProps extends PropsWithChildren {
	chartData: any
	isLoading?: boolean
}

export const LineChart: FC<IProps> = ({ chartData, children, isLoading }) => {
	return (
		<div className='chart-container'>
			{isLoading && <div className={'loading'} />}
			{children}
			<Line
				data={chartData}
				options={{
					aspectRatio: 2,
					scales: {
						y: {
							grid: {
								display: true,
							},
							ticks: {
								display: false,
							},
						},
					},
					plugins: {
						title: {
							display: false,
						},
						legend: {
							display: false,
						},
					},
					elements: {
						line: {
							tension: 0.4,
						},
					},
				}}
			/>
		</div>
	)
}
