import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {
	className?: string
}

export const PopupTitle: React.FC<IProps> = ({ className, children }) => {
	return <h3 className={clsx(className, 'popup__title')}>{children}</h3>
}
