import { useQuery } from '@tanstack/react-query'
import { TUsers } from 'shared/services/types/users.type'
import { UsersService } from 'shared/services/users.service'

export const useGetUsersList = (filter: TUsers.GetList) => {
	const getUsersList = useQuery({
		queryFn: () => UsersService.getList(filter),
		queryKey: ['get users list', filter],
		retry: 0,
		enabled: !!filter.limit,

		select: ({ data }) => data
	})

	return getUsersList
}
