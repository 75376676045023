import { toFormData } from 'axios'
import { axiosClassic } from 'shared/api'
import { getAuthUrl, getUserUrl } from 'shared/api/config/api.config'
import { TAuth } from '../types/auth.type'
import { TTokens } from '../types/common.type'
import { removeTokensStorage, saveTokensStorage } from './auth.helper'

export const AuthService = {
	async login(data: TAuth.Login) {
		const response = await axiosClassic.post<TAuth.LoginSuccess>(
			getAuthUrl('/login'),
			toFormData(data)
		)

		if (response.data.access_token) {
			saveTokensStorage({
				access_token: response.data.access_token,
				refresh_token: response.data.refresh_token
			})
		}

		return response
	},
	async loginGet(data: TAuth.Login) {
		const response = await axiosClassic.get<TAuth.LoginSuccess>(
			getAuthUrl(`/login`),
			{
				params: {
					login: data.login,
					password: data.password
				}
			}
		)

		return response
	},
	async refreshToken() {
		const refresh_token = localStorage.getItem('refresh_token')
		if (!refresh_token) {
			this.logout()
		}
		try {
			const response = await axiosClassic.get<TTokens>(getUserUrl(`/refresh`), {
				headers: {
					Authorization: `Bearer ${refresh_token}`
				}
			})
			if (response.data.access_token) {
				saveTokensStorage(response.data)
			}
			return response
		} catch (error: any) {
			if ([401].includes(error.response.status)) {
				this.logout()
			}
		}
	},

	async logout() {
		removeTokensStorage()
	}
}
