import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { UserContext } from 'shared/context/user.context'
import './App.scss'
import { TanstackQueryClientProvider } from './provider/react-querry'
import { router } from './routes'

export const App = () => {
	return (
		<React.StrictMode>
			<TanstackQueryClientProvider>
				<UserContext.Provider value={{}}>
					<ToastContainer />
					<div className='App'>
						<RouterProvider router={router} />
					</div>
				</UserContext.Provider>
			</TanstackQueryClientProvider>
		</React.StrictMode>
	)
}
