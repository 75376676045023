import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ApplicationsService } from 'shared/services/applications.service'
import { TApplications } from 'shared/services/types/applications.type'

export const useActionsApplications = (id: number) => {
	const queryClient = useQueryClient()

	const deleteApplication = useMutation({
		mutationFn: (id: TApplications.Delete) => ApplicationsService.delete(id),
		mutationKey: ['delete application'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['delete application'] })
		},
	})

	const updateApplication = useMutation({
		mutationFn: (data: TApplications.Update) =>
			ApplicationsService.update(id, data),
		mutationKey: ['update application'],
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['update application'] })
		},
	})

	return { updateApplication, deleteApplication }
}
