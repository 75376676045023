export const COLUMNS = [
	{
		Header: 'Домены',
		accessor: 'domain',
	},
	{
		Header: '',
		accessor: 'action',
	},
]
