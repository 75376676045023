import clsx from 'clsx'
import { Logo } from 'components/logo'
import { ItemLink } from 'entities/side-nav/item-link'
import { useGetCurrentUser } from 'features/users/api'
import { useLocation } from 'react-router-dom'
import {
	ChartIcon,
	CheckoutIcon,
	LogoutIcon,
	NoirInternetIcon,
	UserIcon,
	UsersIcon
} from 'shared/assets/icons'
import { AuthService } from 'shared/services/auth/auth.service'
import { Button } from 'shared/ui'
import { Loader } from 'shared/ui/loader'
import styles from './sidenav.module.scss'

export const SideNav = () => {
	const location = useLocation()
	const { data, isLoading } = useGetCurrentUser()

	const isActive = true
	const navLinks = [
		{
			icon: <ChartIcon />,
			name: 'Статистика',
			url: '/',
			isHidden: data?.role === 'operator'
		},
		{ icon: <CheckoutIcon />, name: 'Заявки', url: '/requests' },
		{
			icon: <NoirInternetIcon />,
			name: 'Landings',
			url: '/landings',
			isHidden: data?.role === 'operator'
		},
		{
			icon: <UsersIcon />,
			name: 'Пользователи',
			url: '/users',
			isHidden: data?.role === 'operator'
		},
		{ icon: <UserIcon />, name: 'Профиль', url: '/profile' }
	]

	return (
		<aside className={clsx(styles.sidenav, 'sidenav')}>
			{isLoading && <Loader isFixed />}
			<header className={clsx(styles.header, 'header')}>
				<Logo />
			</header>
			<nav>
				<div className={clsx(styles.items, 'items')}>
					{navLinks.map(item => {
						if (item.isHidden) return null
						return (
							<ItemLink
								isActive={location.pathname === item.url}
								icon={item.icon}
								url={item.url}
								name={item.name}
							/>
						)
					})}
				</div>
			</nav>
			<footer className={clsx(styles.footer, 'footer')}>
				<div className={clsx(styles.items, 'items')}>
					<div className={clsx(styles.item, 'item')}>
						<span className={clsx(styles.icon)}>
							<LogoutIcon />
						</span>
						<Button text='Выйти' onClick={AuthService.logout} />
					</div>
				</div>
			</footer>
		</aside>
	)
}
