import { toFormData } from 'axios'
import { axiosInstance } from 'shared/api'
import { getApplicationsUrl } from 'shared/api/config/api.config'
import { TApplications } from './types/applications.type'
import { addDays } from 'date-fns'

export const ApplicationsService = {
	async update(id: number, data: TApplications.Update) {
		const response = await axiosInstance.patch<TApplications.UpdateSuccess>(
			getApplicationsUrl('/' + id + '/update'),
			toFormData(data)
		)

		return response
	},

	async getList(data: TApplications.GetList) {
		const response = await axiosInstance.get<TApplications.GetListSuccess>(
			getApplicationsUrl('/list'),
			{
				params: {
					...data,
					limit: data.limit,
					offset: data.offset,
					country: data.country,
					date_from: data.date_from,
					date_to: data?.date_to ? addDays(new Date(data?.date_to), 1)  : null,
					status: data.status,
					order_by: data.order_by,
					is_desc_sort: data.is_desc_sort
				}
			}
		)

		return response
	},

	async getItem(id: TApplications.GetItem) {
		const response = await axiosInstance.get<TApplications.GetItemSuccess>(
			getApplicationsUrl('/' + id)
		)

		return response
	},

	async delete(id: TApplications.Delete) {
		const response = await axiosInstance.delete<TApplications.DeleteSuccess>(
			getApplicationsUrl('/' + id)
		)

		return response
	}
}
