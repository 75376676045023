import clsx from 'clsx'
import React, { FC, PropsWithChildren } from 'react'
import styles from './label.module.scss'

interface IProps extends PropsWithChildren {
	children: React.ReactNode
	htmlFor: string
	labelText: string
	className: any
}

export const Label: FC<IProps> = ({
	children,
	htmlFor,
	className,
	labelText,
}) => {
	return (
		<label
			className={clsx(className, styles.label, 'form__label')}
			htmlFor={htmlFor}
		>
			<span>{labelText}</span>
			{children}
		</label>
	)
}
