import { clsx } from 'clsx'
import { format } from 'date-fns'
import { CloseIcon } from 'entities/close-icon'
import { FC } from 'react'
import { Button } from 'shared/ui'
import { ApplicationDetailsItem } from './ApplicationDetailsItem'

interface IProps {
	onClose: () => void
	data: any
	openPopup: (data: any) => void
}

export const ApplicationDetails: FC<IProps> = ({
	onClose,
	data,
	openPopup,
}) => {
	return (
		<div className={clsx('ApplicationDetails--wrapper')}>
			<div
				className={clsx('ApplicationDetails--background')}
				onClick={onClose}
			></div>
			<div className={clsx('ApplicationDetails')}>
				<CloseIcon className='ApplicationDetails-close' onClick={onClose} />
				<header className={clsx('ApplicationDetails__header')}>
					<h3 className={clsx('ApplicationDetails__title')}>
						Подробная информация о заявке
					</h3>
				</header>
				<div className={clsx('ApplicationDetails__content')}>
					<div className={clsx('ApplicationDetails__items')}>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='ID'>
								<span>{data.id}</span>
							</ApplicationDetailsItem>
							<ApplicationDetailsItem
								title='Статус'
								status={data.status}
							></ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Имя'>
								<span>{data.full_name}</span>
							</ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Комментарий'>
								<span>{data.commentary}</span>
							</ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Геоданные'>
								<span>
									{/* <FlagHindi />
									Sri Lanka (LK) [103.247.51.50] */}
									{data.country}
								</span>
							</ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Адрес электронной почты'>
								<span>{data.email}</span>
							</ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Телефон'>
								<span>{data.phone}</span>
							</ApplicationDetailsItem>
							<ApplicationDetailsItem title='Whatsapp/Telegram'>
								<span>{data.social}</span>
							</ApplicationDetailsItem>
						</div>
						{/* <div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Обратная связь:'>
								<span>
									Метод: <WhatsAppIcon /> Whatsapp
								</span>
								<span>Контакт: kbtwins07@gmail.com</span>
							</ApplicationDetailsItem>
						</div> */}
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Переход с ресурса'>
								<span>{data.application_type}</span>
							</ApplicationDetailsItem>
						</div>
						<div className={clsx('ApplicationDetails__row')}>
							<ApplicationDetailsItem title='Дата и время заявки'>
								{/* <span>{data.created_at}</span> */}
								<span>
									{format(data.created_at, 'dd MMM yyyy ')}в
									{format(data.created_at, ' HH:mm')}
								</span>
							</ApplicationDetailsItem>
						</div>
					</div>
				</div>
				<footer className={clsx('ApplicationDetails__footer')}>
					<Button
						type='submit'
						isLight={data.status !== 'grey'}
						icon={data.status === 'grey' ? 'restore' : 'archive'}
						text={data.status === 'grey' ? 'Восстановить' : 'Архивировать'}
						onClick={() => {
							openPopup(data)
						}}
					/>
				</footer>
			</div>
		</div>
	)
}
